import { createApp } from 'vue'
import App from './App.vue'
import { TUIComponents, TUICore } from './TUIKit';



const SDKAppID = 20003281; 
// const userID = 'sys_online_cs';
// const userID = 'test_4f38fd0c81874a3b';
const userID = GetQueryString('im_user_id');


const TUIKit = TUICore.init({
  SDKAppID,
});

TUIKit.use(TUIComponents);

TUIKit.login({
    userID: userID,
    // userSig: 'eJwtzEELgkAUBOD-8q6FPDfdVqFrEJkiFtVJot3iZa7iumJE-z1Rj-PNMF84RpnTqQZCYA7CcswklW7pQSObj8kr-Sat8ruZB0YWt7omCSFDxBUT7uSqr6lREHLGUAwNTtxSOaDLA0Sx5p4-n9BzuD*J*GVKoe3V20b2ECSXcyH8XZlQxlRgMZWLfafinlfpBn5-I3M0Zw__',
    // userSig: 'eJwtzV0LgjAYBeD-suvQd5vaErqIMigKLBUMhFC35RBj6Pqg6L8n6uV5zoHzRfEhsp6iRT4iFqDZkBUXd6OkGtiIzlwdSZnkUDLM5k5Oi2nY8TrXWnHkEwCghOHRxVurViDfIwRY38DIRjU9Yo8tXMDUncaduvU31f6Shqf1K7ODo443qQRvFdQNDsmnZecHzuydKIsq2iZpkyzR7w*DzDXl',
    userSig: GetQueryString('im_sign'),
});

createApp(App).use(TUIKit).mount('#app')




export function GetQueryString(name:string) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  let context = "";
  if (r != null){
    context = decodeURIComponent(r[2]);
  }
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;
}
